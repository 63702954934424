































import ModalDialog from "@components/ModalDialog.vue";
import AstRadio from "@components/Radio.vue"
import {dlg} from "@utils/dlgUtils";

const SelectTurnaroundTimeDlg = {
  name: 'SelectTurnaroundTimeDialog',
  components: {
    ModalDialog,
    AstRadio,
  },
  props: {
    slaOptions: Array
  },
  data(){
    return {
      slaSelectedOption: '',
    }
  },
  methods: {
    closeModal() {
      dlg.close(this, true, true, false)
    },
    submit() {
      if (this.slaSelectedOption) {
        dlg.close(this, true, this.slaSelectedOption, false)
      }
    }
  },
  computed: {
    actions() {
      return [
        {
          type: 'button',
          name: 'Confirm Submission',
          emit: 'submit',
        },
      ]
    },
    hasError() {
      return !(this.slaSelectedOption.length)
    }
  }
}
export default SelectTurnaroundTimeDlg

export async function openSelectTurnaroundTimeDlg( slaOptions: Array<Object> ) {
  return dlg.open(SelectTurnaroundTimeDlg, { slaOptions }, false)
}
