






import { mapGetters } from 'vuex'
import workflow from '@services/workflow'
import consultations from '@services/consultationService'
import { ReportDetail } from '../../../reporting/classes'
import {openSelectTurnaroundTimeDlg} from "@dialogs/SelectTurnaroundTime.vue";
import { showWaitDlg, closeWaitDlg} from "@dialogs/WaitForProcessDlg.vue"
import { openTimeMessage } from "@dialogs/OneTimeMessage.dialog.vue"

let nextReport: ReportDetail = null

export default {
	name: 'TeleconsultationRequestSubmitReport',
	components: {},
	props: {
		ids: {
			type: Array,
			required: true,
		},
		consultantId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			showAddStudy: false,
			isAddingStudy: false,
			isClientValid: false,
			isPatientValid: false,
			isFieldsValid: false,
			consultations: consultations,
			report: null,
		}
	},
	computed: {
		...mapGetters(['isAuthenticated', 'isCommunityUser']),
		requestConsultant() {
			return workflow.consultant
		},
		template() {
			return this.report && this.report.request
		},
	},
	watch: {
		'report.isValid': {
			handler(isValid) {
				workflow.canGoNext = isValid
			},
			immediate: true,
		},
		async '$route.query.studyId'() {
			const report: ReportDetail = this.report
			if (!report) return // watcher was triggered after report was submitted
			workflow.isLoading = true
			report.restartRequest(this.$route.query.studyId)
			workflow.isLoading = false
		},
	},
	async beforeRouteEnter(to, from, next) {
		workflow.canGoNext = false
		workflow.isLoading = true

		try {
			nextReport = await consultations.startRequestReport(to.query.studyId, to.query.consultantId)
			next()
		} finally {
			workflow.isLoading = false
		}
	},
	async created() {
		this.report = nextReport
		workflow.lastRoute = this.$route.name
		workflow.submitCB = async () => {
			if (!this.report) return Promise.resolve(false)
			let request = this.report.getNewRequestData()
      let consultant = await consultations.getConsultantInfo(request.consultantId)

      if (this.$TELERADIOLOGY_INTEGRATION_CLINIC_CODES.includes(consultant.clinicCode)) {
				// Custom Third-Party Teleradiology Integrations
				showWaitDlg( "Please wait...", "Creating Case", true )
				const radiologyCase = await consultations.createCase(request).catch(e => closeWaitDlg()).then(async r => {
          // @ts-ignore
          const radiologyCaseData = r.data
					if (radiologyCaseData['Success']){

						// VETCT
						if (consultant.clinicCode === this.$VetCTAPI){
							let vetCTCaseData = radiologyCaseData
							if (vetCTCaseData['Success'] && vetCTCaseData['Message']) {
								const slaOptionsResponse = await consultations.getReportSLA(consultant.clinicCode, request.client.clinic, vetCTCaseData['Message'])
          			if (slaOptionsResponse['Data'].length > 0 && slaOptionsResponse['Success']) {
            			const slaOptions = slaOptionsResponse['Data']
            			closeWaitDlg()
            			const slaSelected = await openSelectTurnaroundTimeDlg(slaOptions)
									if(slaSelected !== true){
										showWaitDlg( "Please wait...", "Sending Report", true )
              			const updateReportSLAPayload : ISlaUpdateData = {
                			consultantId: request.consultantId,
                			clientId: request.client.id,
                			caseKey: vetCTCaseData['Message'],
                			patientHistory: this.removeTags(request.values['History.HistoryText']),
                			dateOfBirth: request.patient.birthdate,
                			sla: slaSelected.toString(),
                			question: this.removeTags(request.values['History.Question']),
                			bodyAreas: this.getBodyAreas(request.values)
              			}
              			const updateResponse = await consultations.updateReportSLA(updateReportSLAPayload)
              			request = Object.assign(request, {priority: slaSelected.toString(), caseKey: vetCTCaseData['Message']})
              			return consultations.submitTeleconsultationRequest(request).then(async r => {
                		if (r) {
                  		this.report = null
                  		await consultations.submitCase(consultant.clinicCode, request.client.clinic, vetCTCaseData['Message'])
                		}
                		closeWaitDlg()
                		return r
              			})
									}
          			} else {
            			closeWaitDlg()
            			this.showErrorNotification('An error has occurred retrieving the turnaround time')
          			}
        			}
						}

						// VETSCHOICE
						else if (consultant.clinicCode === this.$VetsChoice_ClinicCode){
							closeWaitDlg()
							if (radiologyCaseData['Message'] === 'VetsChoice Study already exists, attempting image upload again')
								await openTimeMessage("Vets Choice Study already exists","Attempting image upload again")
							else
								await openTimeMessage("Vets Choice Study Created","Images may take some time to upload.")
							return true
						}
					} else {
            	closeWaitDlg()
            	this.showErrorNotification('An error has occurred creating the case')
          }
			})
        return radiologyCase
		}
			else {// Standard consultation request
        return consultations.submitTeleconsultationRequest(request).then(r => {
          if (r === true) this.report = null
          return r
        })
      }
		}
	},
	mounted() {
		this.$store.dispatch('getOnBehalfOfList')
		const nextRoute = this.isCommunityUser ? 'request-consultants' : 'studies'
		workflow.nextRoute = {
			name: nextRoute,
			query: {},
		}
	},
  methods: {
    removeTags(text) {
      return text.replace(/<\/?[^>]+(>|$)/g, "")
    },
		getBodyAreas(values){
			var bodyAreas = [].concat(values['History.BodyArea'])
			if(values['History.AdditionalBodyArea'] !== undefined){
				bodyAreas.concat(values['History.AdditionalBodyArea'])
			}
			return bodyAreas
		},
    showErrorNotification(errMessage) {
      const notification = {
        message: errMessage,
        notificationType: 'error'
      }
      this.$store.dispatch('addNotification', notification)
    }
  }
}
